import { Controller } from "@hotwired/stimulus";

const TOAST_SHOW_TIME_MS = 4000;
const TOAST_FADE_OUT_TIME_MS = 500;

export default class extends Controller {
  static targets = ["toastContainer", "trigger"];

  declare toastContainer: HTMLElement | null;
  declare toastContainerTarget: HTMLElement;
  declare triggerTarget: HTMLElement;
  declare hasTriggerTarget: boolean;
  declare timeout: ReturnType<typeof setTimeout> | null;

  connect() {
    // Show automatically if there is no trigger
    if (!this.hasTriggerTarget) this.show();
  }

  disconnect() {
    if (this.toastContainer) this.hide();
  }

  show() {
    // If a toast is inside of a parent element that has a transform or filter
    // Then the fixed positioning will not be relative to the viewport, so first
    // move the toast target to the body root.
    this.toastContainer = document.body.appendChild(
      this.toastContainerTarget.cloneNode(true),
    ) as HTMLElement;
    const toast = this.toastContainer.querySelector(".toast") as HTMLDivElement;

    this.toastContainer.classList.remove("hidden");
    toast.classList.add("fade-in-up");

    this.timeout = setTimeout(() => {
      toast.classList.remove("fade-in-up");
      toast.classList.add("fade-out-down");
      this.timeout = setTimeout(this.hide.bind(this), TOAST_FADE_OUT_TIME_MS);
    }, TOAST_SHOW_TIME_MS);
  }

  hide() {
    if (!this.toastContainer || !this.toastContainer.parentElement) return;
    if (this.timeout) clearTimeout(this.timeout);
    this.toastContainer.parentElement.removeChild(this.toastContainer);
    this.toastContainer = this.timeout = null;
  }
}
